import PropTypes from 'prop-types';
import React from 'react';
import ROUTE_NAVIGATION from './routeQuery';
import loadable from '../components/loadable';
import { Query } from 'react-apollo';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import { Loader } from '@deity-io/falcon-ecommerce-uikit';
const PageRenderer = loadable(() => import('../components/PageRenderer/PageRenderer'));
const Product = loadable(() => import('../screens/Product/ProductRenderer'));
const Category = loadable(() => import('../screens/Category/Category'));

const sanitizePath = (path) => {
    // Remove beginning slash
    if (path.startsWith('//')) {
        path = path.substring(2);
    }

    if (path.startsWith('/')) {
        path = path.substring(1);
    }

    // Remove end slash
    if (path.endsWith('/')) {
        path = path.slice(0, -1);
    }
    if (path == '') {
        path = '/';
    }
    return path;
}

const DynamicRouteResolver = ({components, location, loaderComponent, errorComponent, ...props}) => {
    const {pathname} = location;
    const path = sanitizePath(pathname);
    const [pageData, setPageData] = React.useState(null);

    return (
      <>
        <Query
            query={ROUTE_NAVIGATION}
            ssr={true}
            variables={
                {
                    url: path
                }
            }
            fetchPolicy="cache-first"
            loaderComponent={loaderComponent}
            onCompleted={(data) => {
                setPageData(data.route)
            }}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return <div><Loader/></div>;
                }
                if (error) {
                    return <div>{error.message}</div>;
                }
                if (!data || !data.route) {
                    return <div><NotFoundPage/></div>
                }
                switch (data.route.__typename) {
                    case 'CmsPage':
                        return (<PageRenderer routeData={data.route} />)
                    case 'SimpleProduct':
                    case 'GroupedProduct':
                        return (<Product routeData={data.route} />)
                    case 'CategoryTree':
                        if (!data.route.uid) {
                            return;
                        }
                        return (
                          <div>
                              <Category
                                uid={data.route.uid}
                                pageData={data.route}
                              />
                          </div>
                        )
                }
            }}
        </Query>
      </>
    )

};

DynamicRouteResolver.propTypes = {
    components: PropTypes.shape({}),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default DynamicRouteResolver;
